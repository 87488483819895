import API from '@/service/project-service';
import Diagram from '../../../public/diagram-script'
import cm from 'maksof-common'

let getToken =  () => { return localStorage.getItem('userAccessToken') }

var cards = [];
let sidebarIems = [];

let drag = (event) => {
    cards.forEach(function(c){
        if(c.id == event.target['id']) {
            c.loc = event.clientX+' '+event.clientY
            event.dataTransfer.setData("node", JSON.stringify(c));
        }
    })
}

let drop = (event) =>{
    event.preventDefault();
    var data = JSON.parse(event.dataTransfer.getData("node"));
    if(!Diagram.diagramObj.isDiagramGenerated) Diagram.initiateDiagramCanvas();
    //DRAW CARD TEMPLATES
    cards.forEach(function(c){
        var iPorts = [], oPorts = [];
        c.inputPorts.forEach(function(p) { iPorts.push(Diagram.makePort(p.name, true)); });
        c.outputPorts.forEach(function(p) { oPorts.push(Diagram.makePort(p.name, false)); });
        console.log(c.icon)
        Diagram.makeTemplate(c.type, c.name, c.icon, c.background, iPorts, oPorts);
    });
    //DRAW DIAGRAM
    Diagram.drawDataFlowDiagram(data);
}

let allowDrop = (event) =>{
    event.preventDefault();
}
let loadDiagram = (diagramObj)=> {
    if (!cm.isEmpty(diagramObj)){
        let diagramObject = JSON.parse(diagramObj);
        Diagram.initiateDiagramCanvas();
        cards.forEach(function(c){
            let iPorts = [], oPorts = [];
            c.inputPorts.forEach(function(p) { iPorts.push(Diagram.makePort(p.name, true)); });
            c.outputPorts.forEach(function(p) { oPorts.push(Diagram.makePort(p.name, false)); });
            Diagram.makeTemplate(c.type, c.name, c.icon, c.background, iPorts, oPorts);
        });
        Diagram.loadDiagram(JSON.stringify(diagramObject));
    }else {
        return;
    }
}


let loadProject = async (projectid) => {
    try{
        let res = await API.getProjectApi('api/project/load',{
            userid      : getToken() , 
            projectid   : projectid,
        })
        return Promise.resolve(res);
    }catch(err){
        return Promise.reject(err);
    }
}

let getAllComponent = async () => {
    try{
        let res = await API.getProjectApi('api/component/getall',{})
        return Promise.resolve(res);
    }catch(err){
        return Promise.reject(err);
    }
}
let updatesource = async (projectid, data) => {
    var body = {
        source      : data,
        projectid   : projectid
    }
    try{
        let res = await API.postProjectApi('api/project/updatesource', body, { userid : getToken() })
        return Promise.resolve(res);
    }catch(err){
        return Promise.reject(err)
    }
}
sidebarIems = getAllComponent().then(res=>{
    res.data.forEach(function(p){
        if(p.child.length != 0) {
            p.child.forEach(function(c){
                if(c.childs.length != 0) {
                    c.childs.forEach(function(gc){
                        var cardId = p.templateType.replace(/[^a-zA-Z]/g, "_").toUpperCase() +"_"+ gc.name.replace(/[^a-zA-Z]/g, "_").toUpperCase();
                        gc.id = cardId;
                        cards.push({
                            id: cardId,
                            icon: String.fromCharCode('0x'+p.icon),
                            background: p.color,
                            type: p.templateType,
                            name: gc.name,
                            description : gc.description,
                            properties: gc.properties,
                            documentation: gc.documentation,
                            inputPorts: gc.input, 
                            outputPorts: gc.output
                        });
                    });
                }
            });
        }
    });
    return {status : "OK", data: res.data};
}).catch(err=> {
    return {status : "FAIL", mesage: "Your session is expired, Please log in back again!", err: err};
})


export  default { drag, drop, allowDrop, loadProject, updatesource, loadDiagram, sidebarIems};