import axios from 'axios';
import config from './service-config'

let postProjectApi = async (url,data,headers) => {
    headers['x-api-key'] = config.apikey;
    try{
        let res = await axios.post(config.baseUrl()+url,data,{ headers : headers, })
        return Promise.resolve(res.data);
    } catch(err){
        return Promise.reject(err)
    }
}

let getProjectApi = async (url,headers,params) => {
    headers['x-api-key'] = config.apikey;
    try{
        let res = await axios.get(config.baseUrl()+url,{ headers : headers,  params : params })
        return Promise.resolve(res.data);
    } catch(err){
        return Promise.reject(err)
    }
}

// let getRequest = async (url,headers,params) => {
//     try{
//         let res = await axios.get(url,{ headers : headers,  params : params })
//         return Promise.resolve(res.data);
//     } catch(err){
//         return Promise.reject(err)
//     }
// }
export  default { postProjectApi, getProjectApi };