<template>
  <div class="content">
      <!-- <Sidenave /> -->
    <div class="aside-left">
            <div class="loader" v-if="leftLoader">
                <ProgressSpinner style="width:50px;height:50px" />
            </div>
        <div class="search-bar">
            <InputText
                type="text"
                class="p-inputtext-sm"
                placeholder="Search"
                v-model="search"
              />
        </div>
      <ul class="list-container">
        <!-- PANEL DATA -->        
        <li class="title-container input-data" v-for='i in filteredItems' :key='i'>
            <div class="d-flex align-items-center list-title collapsed" data-toggle="collapse" :data-target="'#'+i._id" :style="{color : i.color}"><i class="fas fa-caret-right mr-3"></i><span><i class="fas-icon mr-2" style="font-family: 'FontAwesome'">{{String.fromCharCode('0x'+i.icon)}}</i>{{i.name}}</span></div>
            <ul class="list-menu collapse pl-2" :id="i._id">
                <li class="title-container" v-for='c in i.child' :key='c'>
                <div class="d-flex align-items-center list-title sub-menu collapsed" data-toggle="collapse" :data-target="'#'+i._id+'_child'"><i class="fas fa-caret-right mr-3"></i><span>{{c.name}}</span></div>
                <ul class="list-menu collapse pl-2"  :id="i._id+'_child'">
                    <li class="list-item" v-for='gc in c.childs' :key='gc'>
                        <span class="list-node" :id="gc.id" :style="{ 'border-color': i.color+'!important' }" draggable="true" @dragstart="drag($event)">{{gc.name}}</span>
                    </li>
                </ul>
                </li>
            </ul>
        </li>
      </ul>

    </div>
    <div class="aside-center" id="asideCenter" style="background:url('https://files.fm/thumb_show.php?i=7zt59kkam')" @drop="drop($event)" @dragover="allowDrop($event)"  width="336" height="69">
    <div class="loader" v-if="centerLoader">
        <ProgressSpinner style="width:50px;height:50px" />
    </div>
      <div id="myDiagramDiv"></div>
        <div class="operationBtn">
          <button class="icon fas fa-play" style="display: inline-block;"></button>
          <button title="Undo" id= "undo" class="icon fas fa-undo" style="display: inline-block;" @click= "undoOperation()"></button>
          <button title="Redo" class="icon fas fa-redo" style="display: inline-block;" @click= "redoOperation()"></button>
        </div>
    </div>
    <div class="aside-right" id="asideRight">
      <div id="propertiesTab" class="design-header d-flex align-items-center justify-content-between">
        <h1 id= "titleName"></h1>
        <button class="border-0 bg-transparent py-2 px-3" @click="hideSide()"><i class="fas fa-times"></i></button>
      </div>
      <div id="documentationTab" class="data-container">
        <TabView>
          <TabPanel header="Properties">
            <!-- <Message severity="error">
              <h3>Error</h3>
              Some of the required properties are missing
            </Message> -->
            <div class="p-field">
              <label for="username2">Description</label>
              <InputText
                type="text"
                class="p-inputtext-sm"
                placeholder="Description"  id="propsDescription" @change="updateDescription()"
              />
            </div>
            <!-- <div class="p-field">
              <label for="username2">Type</label>
              <InputText
                type="text"
                class="p-invalid p-inputtext-sm"
                placeholder="Request Body"
              />
              <small id="username2-help" class="p-error">Required</small>
            </div>
            <div class="p-field">
              <label for="username2">Request Type</label>
              <Dropdown
                class="p-inputtext-sm"
                optionLabel="name"
                optionValue="code"
                placeholder="Select a City"
              />
            </div>
            <div class="createable-inputs">
              <h4>Request Header</h4>
              <div class="row mx-0 align-items-end">
                <div class="col-5 px-1">
                  <div class="p-field mb-0">
                    <label for="username2">Header</label>
                    <InputText type="text" class="p-inputtext-sm" />
                  </div>
                </div>
                <div class="col-5 px-1">
                  <div class="p-field mb-0">
                    <label for="username2">Value</label>
                    <InputText type="text" class="p-inputtext-sm" />
                  </div>
                </div>
                <div class="col-2 px-1">
                  <Button icon="pi pi-times" />
                </div>
              </div>
              <div class="row mx-0 align-items-end mb-2">
                <div class="col-5 px-1">
                  <div class="p-field mb-0">
                    <label for="username2">Header</label>
                    <InputText type="text" class="p-inputtext-sm" />
                  </div>
                </div>
                <div class="col-5 px-1">
                  <div class="p-field mb-0">
                    <label for="username2">Value</label>
                    <InputText type="text" class="p-inputtext-sm" />
                  </div>
                </div>
                <div class="col-2 px-2">
                  <Button icon="pi pi-times" />
                </div>
              </div>
              <div class="col-12 px-1 mt-2">
                <Button label="Add" class="p-button-sm add-btn" />
              </div>
            </div>
            <InputSwitch v-model="checked1" class="mt-2" /> -->
          </TabPanel>
          <TabPanel header="Documentation">
            <div class="properties-documentation" id="propsDocumentation">
            </div>
          </TabPanel>
        </TabView>
      </div>
       <div class="loader" id="propertiesLoader">
          <ProgressSpinner style="width:50px;height:50px" />
        </div>
    </div>
  </div>
</template>
<script>
import method from './projects';
import Diagram from '@/../public/diagram-script'
export default {
    async mounted(){
        this.loadProject(this.$route.params.projectid);
        this.leftLoader =true;
        let resp = await method.sidebarIems;
        if (resp.status == 'OK') {
            this.leftLoader = false;
            this.component = resp.data;
        } else {
            this.leftLoader = false
            alert(resp.message);
            //LOG OUT
        }
    },
    data() {
        return {
            leftLoader      : false,
            centerLoader    : false,
            component   : null,
            search      : '',
            unicode     : ''
        }
    },
    computed:{
        filteredItems() {
            let component = this.component;
            if (!this.search) return component;
            let searchValue = this.search.toLowerCase()
            let filter = component => {
                component.name.toLowerCase().includes(searchValue)
            } 
            return component.filter(filter)
        }
    },
    methods: {
        isObject(val){
            if ( val === null ) return false;
            return (typeof val === 'object')
        },
        ObjectProps(obj){
            for (let  val in obj){
                if (this.isObject(obj[val])){
                    this.ObjectProps(obj[val]);
                }else{
                    return obj[val]
                }
            }
        },
        updateDescription(){
            Diagram.updateDescription()
        },
        hideSide(){
            $('#asideRight').removeClass('active')
            $('#asideCenter').removeClass('active')
        },
        drag        : (ev) => { method.drag(ev) },
        drop        : (ev) => { method.drop(ev)},
        allowDrop   : (ev) => { method.allowDrop(ev) },
        undoOperation: ()=>{Diagram.undoOperation()},
        redoOperation: ()=>{Diagram.redoOperation()},
        unescape: v => unescape(v),
        async loadProject(projectid){
            this.centerLoader = true
            let res = await method.loadProject(projectid);
            if(res.status == 'OK'){
                this.centerLoader = false;
                method.loadDiagram(res.data[0].source)
            } 
            else {
                alert(res.message);
                this.$router.push('/all-projects');
            }
        }
    },
};


</script>
<style lang="scss" scoped>
@import"projects.scss";
.loader{
    position: absolute;
    background: rgba(255,255,255,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
</style>