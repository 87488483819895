import go from 'gojs'
import method from '../src/views/Projects/projects';

var $ = go.GraphObject.make;
var myDiagram;
var diagramObj = { 
    "isDiagramGenerated": false,
    "nodeDataArray": []
};
var openedPropertKey = null;
function initiateDiagramCanvas() {
    //Create Diagram Canvas Object
    myDiagram = $(go.Diagram, 'myDiagramDiv',{
        "undoManager.isEnabled": true,
    });
    
    //LINKS DESIGN
    myDiagram.linkTemplate = $(go.Link, {
        routing: go.Link.AvoidsNodes,
        curve: go.Link.JumpOver,
        corner: 5, toShortLength: 4,
        resegmentable: true,
        relinkableFrom: true, relinkableTo: true
    },
    $(go.Shape, { stroke: "gray", strokeWidth: 2 })
    );    
    diagramObj.isDiagramGenerated = true;

    //ON EVERY CHANGE OF DIAGRAM HIT API
    myDiagram.addDiagramListener("Modified", async function(e) {
        if(myDiagram.isModified){
            var data = myDiagram.model.toJson();
            let projectid = (window.location.href).split('/');
            var result = await method.updatesource(projectid[4], data);
            if(result.status != 'OK') alert(result.message);
            if (result.message == "invalid access token"){
                alert(result.message)
                localStorage.clear()
                location.href = process.env.BASE_URL
            }
            myDiagram.isModified = false;
        }
    });
}

async function updateDescription() {
    var diagramSource = JSON.parse(myDiagram.model.toJson());
    diagramSource.nodeDataArray.forEach(function(row){
        if(row.key == openedPropertKey) {
            row.description = document.getElementById('propsDescription').value;
        }
    });
    var data = JSON.stringify(diagramSource);
    let projectid = (window.location.href).split('/');
    myDiagram.model = go.Model.fromJson(data);
    var result = await method.updatesource(projectid[4], data);
    if(result.status != 'OK') alert(result.message);
    if (result.message == "invalid access token"){
        alert(result.message)
        localStorage.clear()
        location.href = process.env.BASE_URL
    }
}

function makePort(name, input) {

    var port = $(go.Shape, "Circle", { fill: "white", stroke: "#000", desiredSize: new go.Size(12, 12), cursor: "pointer",  portId: name, toMaxLinks: 1, fromMaxLinks: 1});
    var label = $(go.TextBlock, name,  // the name of the port
        { font: "8pt Montserrat" ,width: 115});

    var panel = $(go.Panel, "Horizontal",
        { margin: new go.Margin(2, 0) });
    
    if (input) {
        port.fromSpot = go.Spot.Right;
        port.toLinkable = true;
        label.margin = new go.Margin(1, 1, 0, 4);
        panel.alignment = go.Spot.TopRight;
        panel.add(port);
        panel.add(label);
    } else {
        port.toSpot = go.Spot.Left;
        port.fromLinkable = true;
        label.textAlign = "right";
        label.margin = new go.Margin(1, 4, 0, 0);
        panel.alignment = go.Spot.TopLeft;
        panel.add(label);
        panel.add(port);
    }
    return panel;
}

//  Context menu
function contextMenuBtn(text, action, visiblePredicate) {
    return $("ContextMenuButton",
            $(go.Shape,'Rectangle',{ stroke: "#000", strokeWidth: 0, fill:"#fff", height : 30}),
            $(go.TextBlock,{ font: "600 9pt Montserrat" } ,text),
            { click: action},
            visiblePredicate ? new go.Binding("visible", "", function(o, e) { return o.diagram ? visiblePredicate(o, e) : false; }).ofObject() : {});
    }
var menu =  // context menu for each Node
$("ContextMenu", "None",
    contextMenuBtn("Cut",
    function(e, obj) { e.diagram.commandHandler.cutSelection();},
    function(o) { return o.diagram.commandHandler.canCutSelection();}),
    contextMenuBtn("Copy",
    function(e, obj) { e.diagram.commandHandler.copySelection(); },
    function(o) { return o.diagram.commandHandler.canCopySelection(); }),
    contextMenuBtn("Paste",
    function(e, obj) { e.diagram.commandHandler.pasteSelection(e.diagram.toolManager.contextMenuTool.mouseDownPoint); },
    function(o) { return o.diagram.commandHandler.canPasteSelection(o.diagram.toolManager.contextMenuTool.mouseDownPoint); }),
    contextMenuBtn("Delete",
    function(e, obj) { e.diagram.commandHandler.deleteSelection(); },
    function(o) { return o.diagram.commandHandler.canDeleteSelection(); }),
    contextMenuBtn("Undo",
    function(e, obj) { e.diagram.commandHandler.undo(); },
    function(o) { return o.diagram.commandHandler.canUndo(); }),
    contextMenuBtn("Redo",
    function(e, obj) { e.diagram.commandHandler.redo(); },
    function(o) { return o.diagram.commandHandler.canRedo(); }),
)
let undoOperation = () => {
    myDiagram.commandHandler.undo()
}
let redoOperation = () =>{
    myDiagram.commandHandler.redo()
}
function makeTemplate(type, name, icon, borderColor ,inports, outports) {
    var node = $(go.Node, "Spot", { name : name , selectionAdorned: true, selectionAdornmentTemplate: $(go.Adornment, "Auto",$(go.Shape, "RoundedRectangle",{ fill: null, stroke: "#29ace0", strokeWidth: 2 }),$(go.Placeholder))}, 	
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),
        $(go.Panel,
        $(go.Shape, "RoundedRectangle", { cursor: PointerEvent, width: 256, height: 143, fill: "white", stroke: borderColor, strokeWidth: 2, click: function(e, obj) { openPropertiesTab(obj.part.data); }})),
        $(go.Panel, "Horizontal", { alignment: new go.Spot(0.5, 0.15) ,margin: 8 },
            $(go.TextBlock, { text: icon , font: '10pt FontAwesome',stroke: borderColor,margin: new go.Margin(0, 2, 0, 0) }),
            $(go.TextBlock, { text: name, font: '600 9pt Montserrat', stroke: borderColor, margin: new go.Margin(0, 0, 0, 10) ,width: 175, overflow: go.TextBlock.OverflowEllipsis,maxLines: 1,}),
            $(go.TextBlock, { text: '\uf058', font: '10pt FontAwesome', stroke: "green", margin: new go.Margin(0, 0, 0, 8) }),
            $("Button", {cursor: PointerEvent,   contextMenu: menu , contextClick: function(e,obj) { e.handled = false},margin: 2, "ButtonBorder.fill": "#fff","ButtonBorder.strokeWidth": 0,  click : function(e, obj) { 
                obj.diagram.select(obj.part.findObject(name));
                e.diagram.commandHandler.showContextMenu(obj);
            }},
            $(go.TextBlock, "\uf142", { font: '10pt FontAwesome', margin: new go.Margin(2, 0, 0, 2)})),
        ),
        $(go.TextBlock, { font: '600 9pt Montserrat', margin: new go.Margin(0, 0, 0, 0),alignment:new go.Spot(0.45, 0.4), width : 200  }, new go.Binding("text", "description")),
        $(go.Panel, "Horizontal", { alignment: new go.Spot(0.3,0.8)}, inports),
        $(go.Panel, "Horizontal", { alignment : new go.Spot(0.7,0.8) }, outports),
        
    );
    myDiagram.nodeTemplateMap.set(type, node);
}

async function drawDataFlowDiagram(node) {
    //DRAW DIAGRAM CODE
    node = {key: diagramObj.nodeDataArray.length+1, type: node.type, name: node.name, properties: node.properties, description: node.description, documentation: node.documentation, loc : node.loc };
    diagramObj.nodeDataArray.push(node);
    var modelObj = JSON.parse(myDiagram.model.toJson());
    modelObj.nodeDataArray.push(node);
    var dObj = { 
        "class": "go.GraphLinksModel",
        "nodeCategoryProperty": "type",
        "linkFromPortIdProperty": "frompid",
        "linkToPortIdProperty": "topid",
        "nodeDataArray": modelObj.nodeDataArray,
        "linkDataArray": modelObj.linkDataArray
    };
    myDiagram.model = go.Model.fromJson(JSON.stringify(dObj));		
}
    
function openPropertiesTab(properties) {
    openedPropertKey = properties.key;
    document.getElementById('titleName').innerHTML= properties.name;
    document.getElementById('propsDescription').value = properties.description;
    document.getElementById('propsDocumentation').innerHTML= properties.documentation;
    document.getElementById('asideRight').classList.add('active');
    document.getElementById('asideCenter').classList.add('active');
    showHidePorperties('flex', 'none');
    setTimeout(function(){ showHidePorperties('none', 'block'); }, 1000);
}

function showHidePorperties(c1, c2) {
    document.getElementById('propertiesLoader').style.display = c1;
    document.getElementById('propertiesTab').style.display = c2;
    document.getElementById('documentationTab').style.display = c2;
}

function loadDiagram(diagramData) {  
    myDiagram.model = go.Model.fromJson(diagramData);
}

export  default { initiateDiagramCanvas, drawDataFlowDiagram, makeTemplate, makePort, diagramObj, loadDiagram, undoOperation,redoOperation, updateDescription};